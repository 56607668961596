import React, { memo } from "react";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import { Image as ImageType, Rate } from "types/business.types";
import { useCanRender } from "hooks/useRender";
import { MdStar } from "react-icons/md";
import SharePopover from "components/Socials/Share";
import { useBusinessProfile } from "hooks/useFetch";
import Carousel from "containers/Cover/Carousel";

type Props = { businessName: string; businessLogo: ImageType; rate: Rate; covers: ImageType[] | undefined };

function Cover({ businessName, businessLogo, rate, covers }: Props) {
	const { data: businessProfile } = useBusinessProfile();
	const widgetSetting = businessProfile?.data;
	const { t } = useTranslation("common");
	const isReviewRenderable = useCanRender("review");

	return (
		<header className="relative group">
			<Carousel businessName={businessName} covers={covers} />

			<div className="absolute z-30 top-4 right-4 md:top-auto md:bottom-8 md:right-8">
				<SharePopover businessName={businessName} />
			</div>
			<div className="absolute flex items-center z-20 bottom-4 left-4 md:bottom-8 md:left-8 text-white space-x-4">
				{businessLogo?.url && (
					<div className="relative w-14 aspect-square md:w-16 lg:w-24">
						<Image
							className="w-full h-full object-contain rounded-full"
							src={businessLogo?.url}
							width={64}
							height={64}
							priority
							alt={businessLogo?.alt || businessName}
						/>
					</div>
				)}

				<div className="flex-flex-column space-y-2">
					<h1 className="text-xl font-normal md:text-3xl lg:text-4xl">{businessName}</h1>

					{isReviewRenderable && (
						<div className="flex flex-row items-center space-x-2 text-sm">
							<span>{rate.score} / 5</span>

							<div className="flex flex-row items-center space-x-2">
								<MdStar className="w-4 h-4" />
								<span>
									{widgetSetting?.rate?.count || 0} {t(`navigation.review`)}
								</span>
							</div>
						</div>
					)}
				</div>
			</div>

			<i className="absolute bottom-0 left-0 right-0 gradient-placeholder z-10 h-full md:rounded-b-xl"></i>
		</header>
	);
}

export default memo(Cover);
