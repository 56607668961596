import { Fragment, useCallback, useMemo, useState, useEffect, memo } from "react";
import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import { FacebookIcon, ShareFillIcon, TwitterIcon } from "components/Icons/Social.icons";
import { generateSocialShare } from "utils/utils";
import useTranslation from "next-translate/useTranslation";
import CopyClipboard from "components/Socials/CopyClipboard";
import useMobile from "hooks/useMobile";

type Props = {
	businessName: string;
};

function SharePopover({ businessName }: Props) {
	const { t } = useTranslation("common");
	const isMobile = useMobile();
	const [url, setUrl] = useState("");

	const mobileShare = useCallback(() => {
		if (navigator.share) {
			navigator
				.share({
					url,
					text: businessName,
					title: businessName,
				})
				.then(() => console.log("Share was successful."))
				.catch((error) => console.log("Sharing failed", error));
		}
	}, [url, businessName]);

	useEffect(() => {
		setUrl(window.location.href.split("?")[0]);
	}, []);

	const links = useMemo(
		() => [
			{
				name: t("social.share_tweet"),
				href: generateSocialShare({ type: "twitter", businessName, url }),
				icon: TwitterIcon,
			},
			{
				name: t("social.share_facebook"),
				href: generateSocialShare({ type: "facebook", url }),
				icon: FacebookIcon,
			},
		],
		[t, businessName, url]
	);

	if (isMobile) {
		return (
			<button
				onClick={mobileShare}
				aria-label="Share Button"
				className="block p-1 transition-colors bg-primary-300 rounded-lg hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-white/50"
			>
				<ShareFillIcon className="rounded-lg text-white h-10 md:h-9" />
			</button>
		);
	} else {
		return (
			<div className="max-w-sm">
				<Popover className="relative">
					{({ open, close }) => (
						<>
							<Popover.Button
								aria-label="Share Button"
								className={classNames(
									{ "bg-primary-500": open },
									"focus:outline-none focus:ring-2 focus:ring-white/50",
									"block p-1 transition-colors bg-primary-300 rounded-lg hover:bg-primary-500"
								)}
							>
								{/* <MdIosShare className="relative -top-[1px] w-7 h-7" /> */}
								<ShareFillIcon className="rounded-lg text-white h-10 md:h-9" />
							</Popover.Button>

							<Transition
								as={Fragment}
								enter="transition ease-out duration-200"
								enterFrom="opacity-0 translate-y-1"
								enterTo="opacity-100 translate-y-0"
								leave="transition ease-in duration-150"
								leaveFrom="opacity-100 translate-y-0"
								leaveTo="opacity-0 translate-y-1"
							>
								<Popover.Panel
									focus={false}
									className={classNames(
										"absolute right-0 z-10 mt-3 w-screen max-w-[16rem]",
										"xl:-translate-x-1/2 xl:transform xl:right-auto xl:left-1/2"
									)}
								>
									<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
										<div className="bg-white p-2">
											{links.map((item) => (
												<a
													key={item.name}
													href={item.href}
													target="_blank"
													className="flex items-center rounded-lg px-4 py-3 transition duration-150 ease-in-out hover:bg-primary-50 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-50 mb-2"
													rel="noreferrer"
												>
													<div className="shrink-0 text-zinc-500">
														<item.icon className="h-4" aria-hidden="true" />
													</div>
													<div className="ml-4">
														<p className="text-sm font-medium text-zinc-800">{item.name}</p>
													</div>
												</a>
											))}
											<CopyClipboard closePopover={close} text={url} />
										</div>
									</div>
								</Popover.Panel>
							</Transition>
						</>
					)}
				</Popover>
			</div>
		);
	}
}

export default memo(SharePopover);
