import { useState, useEffect } from "react";

const useMobile = () => {
	const [mobile, setMobile] = useState<boolean>(false);
	useEffect(() => {
		const userAgent = !!navigator.userAgent.match(
			/android|webos|ip(hone|ad|od)|opera (mini|mobi|tablet)|iemobile|windows.+(phone|touch)|mobile|fennec|kindle (Fire)|Silk|maemo|blackberry|playbook|bb10\; (touch|kbd)|Symbian(OS)|Ubuntu Touch/i
		);

		setMobile(userAgent);
	}, []);

	return mobile;
};

export default useMobile;
