type SVGIconProps = { className?: string; fill?: string };

export const TwitterIcon = ({
  className,
  fill = "currentColor",
}: SVGIconProps) => {
  return (
    <svg
      role="img"
      fill={fill}
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z" />
    </svg>
  );
};

export const TwitterFillIcon = ({
  className,
  fill = "currentColor",
}: SVGIconProps) => {
  return (
    <svg
      role="img"
      fill={fill}
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z" />
    </svg>
  );
};

export const InstagramFillIcon = ({
  className,
  fill = "currentColor",
}: SVGIconProps) => {
  return (
    <svg
      role="img"
      fill={fill}
      className={className}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="a"
        width="24"
        height="24"
        x="4"
        y="4"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path
          fill="#fff"
          d="M16 4c-3.26 0-3.667.015-4.947.072-1.278.06-2.148.261-2.913.558a5.876 5.876 0 0 0-2.126 1.384A5.855 5.855 0 0 0 4.63 8.14c-.297.765-.499 1.635-.558 2.913C4.012 12.333 4 12.74 4 16s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913a5.885 5.885 0 0 0 1.384 2.126A5.868 5.868 0 0 0 8.14 27.37c.766.296 1.636.499 2.913.558 1.28.06 1.687.072 4.947.072s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558a5.898 5.898 0 0 0 2.126-1.384 5.86 5.86 0 0 0 1.384-2.126c.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913a5.89 5.89 0 0 0-1.384-2.126A5.847 5.847 0 0 0 23.86 4.63c-.765-.297-1.636-.499-2.913-.558C19.667 4.012 19.26 4 16 4Zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85 0 3.204-.015 3.585-.074 4.85-.061 1.17-.256 1.805-.421 2.227a3.81 3.81 0 0 1-.899 1.382 3.744 3.744 0 0 1-1.38.896c-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421a3.716 3.716 0 0 1-1.379-.899 3.644 3.644 0 0 1-.9-1.38c-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03Zm0 3.678a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 1 0 0-12.324ZM16 20c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4Zm7.846-10.405a1.441 1.441 0 0 1-2.88 0 1.44 1.44 0 0 1 2.88 0Z"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill="url(#b)"
          d="M4 16c0-4.678 0-7.017 1.073-8.724a7 7 0 0 1 2.203-2.203C8.983 4 11.322 4 16 4s7.017 0 8.724 1.073a7 7 0 0 1 2.203 2.203C28 8.983 28 11.322 28 16s0 7.017-1.073 8.724a6.999 6.999 0 0 1-2.203 2.203C23.017 28 20.678 28 16 28s-7.017 0-8.724-1.073a7 7 0 0 1-2.203-2.203C4 23.017 4 20.678 4 16Z"
        />
        <path
          fill="url(#c)"
          d="M4 16c0-4.678 0-7.017 1.073-8.724a7 7 0 0 1 2.203-2.203C8.983 4 11.322 4 16 4s7.017 0 8.724 1.073a7 7 0 0 1 2.203 2.203C28 8.983 28 11.322 28 16s0 7.017-1.073 8.724a6.999 6.999 0 0 1-2.203 2.203C23.017 28 20.678 28 16 28s-7.017 0-8.724-1.073a7 7 0 0 1-2.203-2.203C4 23.017 4 20.678 4 16Z"
        />
      </g>
      <defs>
        <radialGradient
          id="b"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="translate(5.56 27.472) scale(30.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".09" stopColor="#FA8F21" />
          <stop offset=".78" stopColor="#D82D7E" />
        </radialGradient>
        <radialGradient
          id="c"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="translate(20.632 26.704) scale(26.808)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".64" stopColor="#8C3AAA" stopOpacity="0" />
          <stop offset="1" stopColor="#8C3AAA" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export const FacebookIcon = ({
  className,
  fill = "currentColor",
}: SVGIconProps) => {
  return (
    <svg
      role="img"
      fill={fill}
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
    </svg>
  );
};

export const FacebookFillIcon = ({
  className,
  fill = "currentColor",
}: SVGIconProps) => {
  return (
    <svg
      role="img"
      fill={fill}
      className={className}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#1877f2"
        d="M22 13.461h-4v-3.384c0-.93.72-.846 1.6-.846h1.6V5H18c-1.273 0-2.494.535-3.394 1.487a5.23 5.23 0 0 0-1.406 3.59v3.384H10v4.231h3.2V27H18v-9.308h2.4l1.6-4.23Z"
      />
    </svg>
  );
};

export const YouTubeIcon = ({
  className,
  fill = "currentColor",
}: SVGIconProps) => {
  return (
    <svg
      role="img"
      fill={fill}
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z" />
    </svg>
  );
};

export const YouTubeFillIcon = ({
  className,
  fill = "currentColor",
}: SVGIconProps) => {
  return (
    <svg
      role="img"
      fill={fill}
      className={className}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#ff0000"
        d="M7.263 23.524a2.536 2.536 0 0 1-1.043-.642 2.678 2.678 0 0 1-.642-1.07c-.736-2.093-.952-10.839.462-12.566a2.682 2.682 0 0 1 1.848-.962c3.792-.426 15.51-.368 16.856.141.378.129.724.345 1.012.632.287.287.508.639.646 1.028.802 2.17.829 10.033-.11 12.113-.249.54-.665.98-1.182 1.245-1.414.735-15.972.721-17.848.085v-.004Zm6.021-4.103 6.797-3.68-6.797-3.701v7.381Z"
      />
    </svg>
  );
};

export const WebsiteFillIcon = ({
  className,
  fill = "currentColor",
}: SVGIconProps) => {
  return (
    <svg
      role="img"
      fill={fill}
      className={className}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.49 21.929C22.204 21.049 21.379 20.4 20.4 20.4H19.3V17.1C19.3 16.8083 19.1841 16.5285 18.9778 16.3222C18.7715 16.1159 18.4917 16 18.2 16H11.6V13.8H13.8C14.0917 13.8 14.3715 13.6841 14.5778 13.4778C14.7841 13.2715 14.9 12.9917 14.9 12.7V10.5H17.1C17.6835 10.5 18.2431 10.2682 18.6556 9.85564C19.0682 9.44306 19.3 8.88348 19.3 8.3V7.849C22.523 9.147 24.8 12.304 24.8 16C24.8 18.288 23.92 20.367 22.49 21.929ZM14.9 24.723C10.555 24.184 7.2 20.488 7.2 16C7.2 15.318 7.288 14.658 7.431 14.031L12.7 19.3V20.4C12.7 20.9835 12.9318 21.5431 13.3444 21.9556C13.7569 22.3682 14.3165 22.6 14.9 22.6M16 5C14.5555 5 13.1251 5.28452 11.7905 5.83733C10.4559 6.39013 9.24327 7.20038 8.22183 8.22183C6.15893 10.2847 5 13.0826 5 16C5 18.9174 6.15893 21.7153 8.22183 23.7782C9.24327 24.7996 10.4559 25.6099 11.7905 26.1627C13.1251 26.7155 14.5555 27 16 27C18.9174 27 21.7153 25.8411 23.7782 23.7782C25.8411 21.7153 27 18.9174 27 16C27 14.5555 26.7155 13.1251 26.1627 11.7905C25.6099 10.4559 24.7996 9.24327 23.7782 8.22183C22.7567 7.20038 21.5441 6.39013 20.2095 5.83733C18.8749 5.28452 17.4445 5 16 5V5Z" />
    </svg>
  );
};

export const CopyFillIcon = ({
  className,
  fill = "currentColor",
}: SVGIconProps) => {
  return (
    <svg
      role="img"
      fill={fill}
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.2631 2H4.89474C3.85263 2 3 2.81818 3 3.81817V16.5455H4.89474V3.81817H16.2631V2ZM15.3158 5.63635H8.68421C7.64215 5.63635 6.79892 6.45458 6.79892 7.45454L6.78952 20.1818C6.78952 21.1818 7.63264 22 8.67471 22H19.1052C20.1474 22 21 21.1818 21 20.1818V11.0909L15.3158 5.63635ZM8.68421 20.1818V7.45454H14.3684V12H19.1052V20.1818H8.68421Z" />
    </svg>
  );
};

export const ShareFillIcon = ({
  className,
  fill = "currentColor",
}: SVGIconProps) => {
  return (
    <svg
      role="img"
      fill={fill}
      className={className}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <path className="text-primary-500" d="M0 0h32v32H0z" /> */}

      <path d="M19.6665 8.58317L18.3648 9.88484L16.9073 8.42734V18.6665H15.0923V8.42734L13.6348 9.88484L12.3332 8.58317L15.9998 4.9165L19.6665 8.58317ZM23.3332 13.1665V23.2498C23.3332 24.2582 22.5082 25.0832 21.4998 25.0832H10.4998C10.0136 25.0832 9.54729 24.89 9.20347 24.5462C8.85966 24.2024 8.6665 23.7361 8.6665 23.2498V13.1665C8.6665 12.149 9.48234 11.3332 10.4998 11.3332H13.2498V13.1665H10.4998V23.2498H21.4998V13.1665H18.7498V11.3332H21.4998C21.9861 11.3332 22.4524 11.5263 22.7962 11.8701C23.14 12.214 23.3332 12.6803 23.3332 13.1665Z" />
    </svg>
  );
};

// export const LinkedInIcon = ({ className, fill = "currentColor" }: SVGIconProps) => {
// 	return (
// 		<svg role="img" fill={fill} className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
// 			<path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
// 		</svg>
// 	);
// };

// export const TelegramIcon = ({ className, fill = "currentColor" }: SVGIconProps) => {
// 	return (
// 		<svg role="img" fill={fill} className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
// 			<path d="M11.944 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0a12 12 0 0 0-.056 0zm4.962 7.224c.1-.002.321.023.465.14a.506.506 0 0 1 .171.325c.016.093.036.306.02.472-.18 1.898-.962 6.502-1.36 8.627-.168.9-.499 1.201-.82 1.23-.696.065-1.225-.46-1.9-.902-1.056-.693-1.653-1.124-2.678-1.8-1.185-.78-.417-1.21.258-1.91.177-.184 3.247-2.977 3.307-3.23.007-.032.014-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.14-5.061 3.345-.48.33-.913.49-1.302.48-.428-.008-1.252-.241-1.865-.44-.752-.245-1.349-.374-1.297-.789.027-.216.325-.437.893-.663 3.498-1.524 5.83-2.529 6.998-3.014 3.332-1.386 4.025-1.627 4.476-1.635z" />
// 		</svg>
// 	);
// };
