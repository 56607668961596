import React, { memo, useState, useEffect, useCallback } from "react";
import Image from "next/image";
import classNames from "classnames";
import { Image as ImageType } from "types/business.types";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

type Props = { businessName: string; covers: ImageType[] | undefined };

function Carousel({ businessName, covers }: Props) {
	const [counter, setCounter] = useState(1);
	const [pause, setPause] = useState(false);

	const length = covers?.length || 0;

	const handleNext = useCallback(() => {
		if (counter !== length) {
			setCounter(counter + 1);
		} else {
			setCounter(1);
		}
	}, [counter, length]);

	const handlePrevious = useCallback(() => {
		if (counter > 1) {
			setCounter(counter - 1);
		} else {
			setCounter(length);
		}
	}, [counter, length]);

	useEffect(() => {
		let interval = setInterval(() => {
			if (!pause) {
				handleNext();
			} else {
				clearInterval(interval);
			}
		}, 5000);
		return () => clearInterval(interval);
	});

	return (
		<>
			{length > 1 && (
				<>
					<button
						type="button"
						onClick={handleNext}
						className="group-hover:opacity-100 md:opacity-0 opacity-100 absolute text-white rounded-full p-3 md:p-2 top-1/2 left-3 md:left-8 -translate-y-1/2 bg-[#000]/40 hover:bg-[#000] transition-all z-30 cursor-pointer appearance-none"
					>
						<MdKeyboardArrowLeft className="w-5 h-5" />
					</button>
					<button
						type="button"
						onClick={handlePrevious}
						className="group-hover:opacity-100 md:opacity-0 opacity-100 absolute text-white rounded-full p-3 md:p-2 top-1/2 right-3 md:right-8 -translate-y-1/2 bg-[#000]/40 hover:bg-[#000] transition-all z-30 cursor-pointer appearance-none"
					>
						<MdKeyboardArrowRight className="w-5 h-5" />
					</button>
				</>
			)}
			<div className="relative h-[300px] lg:h-auto lg:aspect-[4] w-full bg-primary-500 overflow-hidden md:rounded-xl">
				{covers?.map((item, i) => (
					<div
						className={classNames("absolute inset-0 transition ease-out duration-700", {
							"opacity-100": counter - 1 === i,
							"opacity-0": counter - 1 !== i,
						})}
						key={i}
					>
						<Image
							fill
							className="object-cover object-center select-none"
							src={item?.url}
							alt={businessName}
							sizes="(max-width: 768px) 50vw, (max-width: 1200px) 50vw, 33vw"
							priority={i === 0}
						/>
					</div>
				))}
			</div>
		</>
	);
}

export default memo(Carousel);
