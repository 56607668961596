import React, { useCallback } from "react";
import useCopyToClipboard from "hooks/useClipboard";
import { toast } from "react-hot-toast";
import { MdCheckCircle } from "react-icons/md";
import { CopyFillIcon } from "components/Icons/Social.icons";
import useTranslation from "next-translate/useTranslation";

type Props = {
	text: string;
	closePopover: () => void;
};

const CopyClipboard = ({ text, closePopover }: Props) => {
	const [_, copy] = useCopyToClipboard();
	const { t } = useTranslation("common");

	const addToast = useCallback(() => {
		copy(text);
		closePopover();
		toast.custom(
			(h) => (
				<div
					id="toast-undo"
					className="flex items-center p-4 w-full max-w-xs text-gray-700 bg-white shadow-xl rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5"
					role="alert"
				>
					<MdCheckCircle className="w-5 h-5 text-primary-500 mr-2" />
					<div className="text-sm font-normal">{t("social.copy_clipboard_done")}</div>
				</div>
			),
			{
				id: "clipboard",
			}
		);
	}, [copy, t, text, closePopover]);

	return (
		<button
			type="button"
			className="flex w-full items-center text-left rounded-lg px-4 py-3 transition duration-150 ease-in-out hover:bg-primary-50 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-50"
			aria-label="Copy to Clipboard"
			onClick={addToast}
		>
			<div className="shrink-0 text-zinc-500">
				<CopyFillIcon className="w-4" />
			</div>
			<div className="ml-4">
				<p className="text-sm font-medium text-zinc-800">{t("social.copy_clipboard")}</p>
			</div>
		</button>
	);
};

export default CopyClipboard;
